import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { GET_PLANT } from '../../constants/config';
import './style.css';
// import { BsDownload } from 'react-icons/bs';
// import { FaShare } from 'react-icons/fa';
// import Similar from '../../components/Similar';
import { Link } from 'react-router-dom';
import ImageError from '../../assets/images/image_error.png';
import { BACKEND } from '../../constants/config';
import Viewer from 'react-viewer';
import { BsThreeDots } from 'react-icons/bs';
import { TbExternalLink } from 'react-icons/tb';
import { Helmet } from 'react-helmet';

const Index = () => {
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const [value, setValue] = useState([]);

  useEffect(() => {
    // Disable text selection for elements
    // with class "no-select"
    const noSelectElements = document.querySelectorAll('.no-select');
    noSelectElements.forEach((element) => {
      element.style.webkitUserSelect = 'none';
      element.style.mozUserSelect = 'none';
      element.style.msUserSelect = 'none';
      element.style.userSelect = 'none';
    });
  }, []);
  useEffect(() => {
    const getSinglePlant = async () => {
      await axios({
        url: GET_PLANT + splitLocation[2],
        method: 'GET',
        dataType: 'json',
      })
        .then(({ data }) => {
          setValue(data.plant);
        })
        .catch((e) => {
          alert('Network Error');
        });
    };
    getSinglePlant();
  }, []);
  return (
    <>
      <section className="gray-simple no-select">
        {value.length === 0 ? (
          <div id="preloader">
            <div className="preloader">
              <span></span>
              <span></span>
            </div>
          </div>
        ) : (
          <div className="container">
            <Helmet>
              <title>{value.binomial_name}</title>
              <meta
                name="description"
                content={`${value.nepali_name ? value.nepali_name : 'N/A'} or ${
                  value.synonym
                } is ${value.kingdom} commonly found in  ${
                  value.habitat ? value.habitat : 'N/A'
                } `}
              />
            </Helmet>
            <div
              className="row"
              style={{
                pointerEvents: 'none',
              }}
            >
              <div className="col-lg-1 col-md-1"></div>
              <div className="col-lg-10 col-md-12 col-sm-12">
                <div className="property_block_wrap style-2 p-4">
                  <div className="row">
                    <div className="col-lg-7 col-md-12 col-sm-12">
                      <div className="prt-detail-title-desc">
                        <span className="prt-types sale">{value.kingdom}</span>
                        <h3 style={{ textTransform: 'none' }}>
                          {value.binomial_name}
                        </h3>
                        <p>
                          <b>Synonym :</b> <i>{value.synonym}</i>
                        </p>
                        <p>
                          <b>Nepali Name : </b>
                          {value.nepali_name ? value.nepali_name : 'N/A'}
                        </p>
                        <p>
                          <b>English Name : </b>
                          {value.english_name ? value.english_name : 'N/A'}
                        </p>
                        <p>
                          <b>Sanskrit Name : </b>
                          {value.sanskrit_name ? value.sanskrit_name : 'N/A'}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12">
                      <div className="listing-img-wrapper br-16">
                        <img
                          src={value.image ? BACKEND + value.image : ImageError}
                          className="img-fluid mx-auto"
                          onClick={(e) => setVisible(true)}
                          style={{ cursor: 'pointer' }}
                          alt="Plant"
                        />
                        <Viewer
                          visible={visible}
                          onClose={() => {
                            setVisible(false);
                          }}
                          images={[
                            {
                              src: value.image
                                ? BACKEND + value.image
                                : ImageError,
                              alt: 'Plant',
                            },
                          ]}
                        />
                      </div>
                      <span>
                        <b>
                          <i>Source: {value.image_source}</i>
                        </b>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="property_block_wrap style-2">
                  <div className="property_block_wrap_header">
                    <h4 className="property_block_title">
                      General Plant Information
                    </h4>
                  </div>
                  <div
                    id="clOne"
                    className="panel-collapse"
                    aria-labelledby="clOne"
                  >
                    <div className="block-body">
                      <ul className="deatil_features">
                        <li>
                          <strong>Kingdom:</strong>
                          {value.kingdom ? value.kingdom : 'N/A'}
                        </li>
                        <li>
                          <strong>Division:</strong>
                          {value.division}
                        </li>
                        <li>
                          <strong>Class:</strong>{' '}
                          {value.class ? value.class : 'N/A'}
                        </li>
                        <li>
                          <strong>Order</strong>
                          {value.order ? value.order : 'N/A'}
                        </li>
                        <li>
                          <strong>Family:</strong>
                          {value.family ? value.family : 'N/A'}
                        </li>
                        <li>
                          <strong>Genus:</strong>
                          <i>{value.genus ? value.genus : 'N/A'}</i>
                        </li>
                        <li>
                          <strong>Species:</strong>
                          <i>{value.species ? value.species : 'N/A'}</i>
                        </li>
                      </ul>
                      <hr className="m-t-15" />
                      <ul className="deatil_features deatil_features_full">
                        <li>
                          <strong>Parts Used:</strong>
                          {value.parts_used ? value.parts_used : 'N/A'}
                        </li>
                        <li>
                          <strong>Habitat/Distribution:</strong>
                          {value.habitat ? value.habitat : 'N/A'}
                        </li>
                        <li>
                          <strong>Conservation Status:</strong>{' '}
                          {value.conservation_status
                            ? value.conservation_status
                            : 'N/A'}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="property_block_wrap style-2">
                  <div className="property_block_wrap_header">
                    <h4 className="property_block_title">
                      Major Documentation
                    </h4>
                  </div>
                  <div id="clTwo" className="panel-collapse collapse show">
                    <div className="block-body">
                      <p>{value.documentation ? value.documentation : 'N/A'}</p>
                    </div>
                  </div>
                </div>

                <div className="property_block_wrap style-2">
                  <div className="property_block_wrap_header">
                    <h4 className="property_block_title">
                      Ethnobotanical Uses
                    </h4>
                  </div>

                  <div id="clThree" className="panel-collapse collapse show">
                    <div className="block-body">
                      <ul className="avl-features third color">{value.uses}</ul>
                    </div>
                  </div>
                </div>

                <div className="property_block_wrap style-2">
                  <div className="property_block_wrap_header">
                    <h4 className="property_block_title">
                      Chemical Constituents
                    </h4>
                  </div>
                  <div id="clTwo" className="panel-collapse collapse show">
                    <div className="block-body">
                      <div className="like_share_wrap b-0 cont">
                        {value.phytomed.map((a, i) =>
                          a.name === 'none' ? (
                            <div>
                              No chemical constituents recorded in our library
                              yet
                            </div>
                          ) : (
                            <Link
                              className="btn besal-underline"
                              to={`/chemical-library/${a.slug}`}
                              key={i}
                            >
                              <BsThreeDots className="link-dot" />
                              {a.name}
                              <TbExternalLink className="link-icon-cus" />
                            </Link>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="property_block_wrap style-2">
                  <div className="property_block_wrap_header">
                    <h4 className="property_block_title">References</h4>
                  </div>
                  <div id="clTwo" className="panel-collapse collapse show">
                    <div className="block-body">
                      <ul className="deatil_features deatil_features_full">
                        <li>
                          <strong>{1}. </strong>
                          <span>
                            Baral, S.R. and Kurmi, P.P. (2006) A Compendium of
                            Medicinal Plants in Nepal. Mass Printing Press,
                            Kathmandu.
                          </span>
                        </li>
                        {value.references.map((a, i) => (
                          <>
                            {a !== ' ' && (
                              <li key={i}>
                                <strong>{i + 2}. </strong>
                                <span href="/">{a}</span>
                              </li>
                            )}
                          </>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="like_share_wrap">
                  <ul className="like_share_list">
                    <li>
                      <a
                        href="/"
                        className="btn btn-likes"
                        data-toggle="tooltip"
                        data-original-title="Download"
                      >
                        <BsDownload className="shareIcons" />
                        Export
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="btn btn-likes"
                        data-toggle="tooltip"
                        data-original-title="Share"
                      >
                        <FaShare className="shareIcons" />
                        Share
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="details-sidebar">
                  <div className="sidebar-widgets">
                    <h4>Similar Items</h4>
                    <Similar slug={splitLocation} />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Index;
