import React, { useEffect, useState } from 'react';
import { GrClose } from 'react-icons/gr';
import Select from 'react-select';
import { GET_SEARCH_FILTER } from '../../constants/config';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const Index = (props) => {
  const [kingdom, setKingdom] = useState([]);
  const [division, setDivision] = useState([]);
  const [chemical, setChemical] = useState([]);
  const [uses, setUses] = useState([]);
  let history = useHistory();

  let query = {};

  const handleChange = async (value, action) => {
    if (action.name === 'kingdom') {
      const type = 'kingdom';
      const data = value.label;
      query = { ...query, [type]: data };
    }

    if (action.name === 'division') {
      const type = 'division';
      const data = value.label;
      query = { ...query, [type]: data };
    }

    if (action.name === 'chemical') {
      const type = 'chemical';
      const data = value.value;
      query = { ...query, [type]: data };
    }

    if (action.name === 'uses') {
      const type = 'uses';
      const data = value.value;
      query = { ...query, [type]: data };
    }
  };

  const getSearchURL = () => {
    const url = `/plantae/kingdom=${query.kingdom}&division=${query.division}&phytomed=${query.chemical}&keywords=${query.uses}`;
    history.push(url);
  };

  useEffect(() => {
    const getsearch = async () => {
      await axios({
        url: GET_SEARCH_FILTER,
        method: 'GET',
        dataType: 'json',
      })
        .then(({ data }) => {
          setKingdom(
            data.results.kingdom.map((a, i) => ({
              label: a,
              value: i,
            }))
          );
          setDivision(
            data.results.division.map((a, i) => ({
              label: a,
              value: i,
            }))
          );
          setChemical(
            data.results.chemical.map((a, i) => ({
              label: a.name,
              value: a._id,
            }))
          );
          setUses(
            data.results.uses.map((a, i) => ({
              label: a.name,
              value: a._id,
            }))
          );
        })
        .catch((e) => {
          alert('Network Error');
        });
    };
    getsearch();
  }, []);

  return (
    <>
      <div
        className={`modal fade show ${props.modal && 'showmodal'}`}
        id="login"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="registermodal"
        aria-modal="true"
      >
        <div
          className="modal-dialog modal-dialog-centered login-pop-form"
          role="document"
        >
          <div className="modal-content" id="registermodal">
            <span
              className="mod-close"
              data-bs-dismiss="modal"
              aria-hidden="true"
            >
              <GrClose
                className="ti-close"
                onClick={(e) => props.modalset(false)}
              />
            </span>
            <div className="modal-body">
              <h2 className="modal-header-title">Advanced Search</h2>
              <div className="login-form">
                <div className="form-group">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group">
                      <label>Kingdom</label>
                      <Select
                        options={kingdom}
                        classNamePrefix="select"
                        onChange={handleChange}
                        name="kingdom"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group">
                      <label>Division</label>
                      <Select
                        options={division}
                        classNamePrefix="select"
                        onChange={handleChange}
                        name="division"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group">
                      <label>Chemical</label>
                      <Select
                        options={chemical}
                        classNamePrefix="select"
                        onChange={handleChange}
                        name="chemical"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group">
                      <label>Application</label>
                      <Select
                        options={uses}
                        classNamePrefix="select"
                        onChange={handleChange}
                        name="uses"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-md full-width btn-default rounded mt-4"
                      onClick={getSearchURL}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.modal && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default Index;
