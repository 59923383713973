import React, { useState } from 'react';
import './style.css';
import { GrClose } from 'react-icons/gr';
import {
  FaFacebookSquare,
  FaWhatsapp,
  FaLinkedin,
  FaMailchimp,
} from 'react-icons/fa';

import {
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  TelegramShareButton,
  TelegramIcon,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from 'react-share';

const ShareModal = (props) => {
  const url = 'https://www.phytomednepal.com' + props.url;
  return (
    <>
      <div
        className={`modal fade show ${props.modal && 'showmodal'}`}
        id="login"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="registermodal"
        aria-modal="true"
      >
        <div
          className="modal-dialog modal-dialog-centered login-pop-form"
          role="document"
        >
          <div className="modal-content" id="registermodal">
            <span
              className="mod-close"
              data-bs-dismiss="modal"
              aria-hidden="true"
            >
              <GrClose
                className="ti-close"
                onClick={(e) => props.modalset(false)}
              />
            </span>
            <div className="modal-body">
              <h2 className="modal-header-title" style={{ fontSize: '20px' }}>
                Select share options:
              </h2>
              <div className="login-form mt-4">
                <ul
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  <li>
                    <FacebookShareButton url={url} className="mx-1">
                      <FaFacebookSquare size={40} color="blue" />
                    </FacebookShareButton>
                  </li>
                  <li>
                    <FacebookMessengerShareButton url={url} className="mx-1">
                      <FacebookMessengerIcon size={40} />
                    </FacebookMessengerShareButton>
                  </li>
                  <li>
                    <WhatsappShareButton url={url} className="mx-1">
                      <FaWhatsapp size={40} color="green" />
                    </WhatsappShareButton>
                  </li>
                  <li>
                    <LinkedinShareButton url={url} className="mx-1">
                      <FaLinkedin size={40} color="#0077B5" />
                    </LinkedinShareButton>
                  </li>
                  <li>
                    <TwitterShareButton url={url} className="mx-1">
                      <TwitterIcon size={40} color="#0077B5" />
                    </TwitterShareButton>
                  </li>
                  <li>
                    <EmailShareButton url={url} className="mx-1">
                      <FaMailchimp size={40} color="pink" />
                    </EmailShareButton>
                  </li>
                  <li>
                    <TelegramShareButton url={url} className="mx-1">
                      <TelegramIcon size={40} />
                    </TelegramShareButton>
                  </li>
                  <li>
                    <RedditShareButton url={url} className="mx-1">
                      <RedditIcon size={40} />
                    </RedditShareButton>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.modal && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default ShareModal;
