import React, { useState } from 'react';
import './style.css';
import data from '../../constants/periodic-table.json';
import Modal from '../Modal';

const colorMap = {
  'noble gas': '#FFBC42',
  'alkaline earth metal': '#EC674E',
  'diatomic nonmetal': '#D81159',
  'alkali metal': '#8F2D56',
  'transition metal': '#58586B',
  'post-transition metal': '#218380',
  lanthanide: '#4AABAF',
  metalloid: '#73D2DE',
};

const Index = () => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState([]);

  const showPopup = (e) => {
    setModal(true);
    setModalData(data.elements.filter((item) => item.number === e));
  };
  return (
    <>
      <Modal
        modalset={(modal) => setModal(modal)}
        modal={modal}
        modalData={modalData}
      />
      <div className="periodic-table">
        {data.elements.map((element) => (
          <div
            className="element"
            key={element.name}
            style={{
              gridRow: element.ypos,
              gridColumn: element.xpos,
              borderColor: colorMap[element.category],
              cursor: 'pointer',
            }}
            onClick={(e) => showPopup(element.number)}
          >
            <strong>{element.symbol}</strong>
            <small className="number">{element.number}</small>
            <small className="name">{element.name}</small>
          </div>
        ))}
      </div>
    </>
  );
};

export default Index;
