import React, { useEffect, useState, useMemo } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import { GET_CHEMICAL } from '../../constants/config';
import './style.css';
import initRDKit from '../../utils/init';
import { BsDownload } from 'react-icons/bs';
import { FaShare } from 'react-icons/fa';
import Downloader from '../../components/Downloader';
import { Stage, Component } from 'react-ngl';
import { useSelector } from 'react-redux';
import ShareModal from '../../components/ShareModal';

const Index = () => {
  let history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const [rdKitLoaded, setRdKitLoaded] = useState(false);
  const [svg, setSvg] = useState('');
  const splitLocation = pathname.split('/');
  const [value, setValue] = useState([]);
  const [modal, setModal] = useState(false);
  const [sharemodal, setShareModal] = useState(false);
  const [molecule, setMolecule] = useState('');
  const auth = useSelector((state) => state.auth);

  const reprList = useMemo(
    () => [
      {
        type: 'ball+stick',
      },
    ],
    []
  );

  const handleDownload = () => {
    if (auth.isAuthenticated) {
      setModal(true);
    } else {
      alert('You must login to download');
    }
  };

  useEffect(() => {
    // Disable text selection for elements
    // with class "no-select"
    const noSelectElements = document.querySelectorAll('.no-select');
    noSelectElements.forEach((element) => {
      element.style.webkitUserSelect = 'none';
      element.style.mozUserSelect = 'none';
      element.style.msUserSelect = 'none';
      element.style.userSelect = 'none';
    });
  }, []);

  useEffect(() => {
    const getSingleChemical = async () => {
      await axios({
        url: GET_CHEMICAL + splitLocation[2],
        method: 'GET',
        dataType: 'json',
      })
        .then(({ data }) => {
          if (data.library.formula === 'a') {
            history.replace('/chemical-not-found');
          }
          setValue(data.library);
        })
        .catch((e) => {
          alert('Network Error');
        });
    };

    const loadRDKIT = async () => {
      await initRDKit()
        .then((e) => {
          setRdKitLoaded(true);
          const mol = window.RDKit.get_mol(value.smiles);
          var sdfoutput = new Blob([mol.get_aromatic_form()], {
            type: 'text/plain;charset=utf-8',
          });
          var file = new File([sdfoutput], `${value.name}.sdf`);
          setSvg(mol.get_svg());
          setMolecule(file);
        })
        .catch((error) => {
          setRdKitLoaded(false);
        });
    };
    getSingleChemical();
    loadRDKIT();
  }, [value.smiles]);

  const handleShare = () => {
    setShareModal(true);
  };

  return (
    <>
      <section className="gray-simple no-select">
        {value.length === 0 ? (
          <div id="preloader">
            <div className="preloader">
              <span></span>
              <span></span>
            </div>
          </div>
        ) : (
          <div className="container">
            {modal && (
              <Downloader
                modalset={(modal) => setModal(modal)}
                modal={modal}
                smiles={value.smiles}
                name={value.name}
              />
            )}

            {sharemodal && (
              <ShareModal
                modalset={(sharemodal) => setShareModal(sharemodal)}
                modal={sharemodal}
                url={location.pathname}
              />
            )}

            {value.formula === 'N/A' ? (
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="property_block_wrap style-2 p-4">
                    <div className="prt-detail-title-desc">
                      <span className="prt-types sale">{value.phytomed}</span>
                      <h3 className="mt-2">{value.name}</h3>
                      <p>
                        {value.name} are the group of chemical compounds. Hence
                        we donot have collection of these datas.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="property_block_wrap style-2 p-4">
                    <div className="prt-detail-title-desc">
                      <span className="prt-types sale">{value.phytomed}</span>
                      <h3 className="mt-2">{value.name}</h3>
                      <p>
                        <b>Molecular Weight :</b>{' '}
                        {value.molwt ? value.molwt : 'N/A'}
                      </p>
                      <p>
                        <b>Molecular Formula :</b> {value.formula}
                      </p>
                    </div>
                  </div>

                  <div className="property_block_wrap style-2">
                    <div className="property_block_wrap_header">
                      <h4 className="property_block_title">
                        Names and Identifiers
                      </h4>
                    </div>
                    <div
                      id="clOne"
                      className="panel-collapse"
                      aria-labelledby="clOne"
                    >
                      <div className="block-body">
                        <p>
                          <b>INCHL KEY :</b>{' '}
                          {value.inchlKey ? value.inchlKey : 'N/A'}
                        </p>
                        <p>
                          <b>INCHL :</b> {value.inchl ? value.inchl : 'N/A'}
                        </p>
                        <p>
                          <b>Smiles :</b> {value.smiles ? value.smiles : 'N/A'}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="property_block_wrap style-2">
                    <div className="property_block_wrap_header">
                      <h4 className="property_block_title">
                        Chemical Structure: 2D
                      </h4>
                    </div>
                    <div
                      id="clOne"
                      className="panel-collapse"
                      aria-labelledby="clOne"
                    >
                      <div className="block-body">
                        <div
                          dangerouslySetInnerHTML={{ __html: svg }}
                          style={{ textAlign: 'center' }}
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div className="property_block_wrap style-2">
                    <div className="property_block_wrap_header">
                      <h4 className="property_block_title">
                        Chemical Structure : 3D
                      </h4>
                    </div>
                    <div
                      id="clOne"
                      className="panel-collapse"
                      aria-labelledby="clOne"
                    >
                      <div className="block-body">
                        {molecule !== '' && (
                          <Stage
                            width="100%"
                            height="400px"
                            cameraState={{ distance: -20 }}
                          >
                            <Component path={molecule} reprList={reprList} />
                          </Stage>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="property_block_wrap style-2">
                    <div className="property_block_wrap_header">
                      <h4 className="property_block_title">
                        Chemical and Physical Properties
                      </h4>
                    </div>
                    <div id="clTwo" className="panel-collapse collapse show">
                      <div className="block-body">
                        <div className="block-wrap">
                          <div className="block-header">
                            <h4 className="block-title">Druglikeness</h4>
                          </div>

                          <div className="block-body">
                            <ul className="dw-proprty-info">
                              <li>
                                <strong>Monoisotopic Mass</strong>
                                {value.monoisotopicMass}
                              </li>
                              <li>
                                <strong>cLogP</strong>
                                {value.cLogP}
                              </li>
                              <li>
                                <strong>cLogS</strong>
                                {value.cLogS}
                              </li>
                              <li>
                                <strong>H-Acceptor</strong>
                                {value.hacceptor}
                              </li>
                              <li>
                                <strong>H-donor</strong>
                                {value.hdonnor}
                              </li>
                              <li>
                                <strong>Total Surface Area</strong>
                                {value.totalSurface}
                              </li>
                              <li>
                                <strong>Relative PSA</strong>
                                {value.relativePsa}
                              </li>
                              <li>
                                <strong>Polar Surface</strong>
                                {value.polarSurface}
                              </li>
                              <li>
                                <strong>Drug Likeness</strong>
                                {value.druglikeness}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="block-wrap">
                          <div className="block-header">
                            <h4 className="block-title">Toxicity</h4>
                          </div>

                          <div className="block-body">
                            <ul className="dw-proprty-info">
                              <li>
                                <strong>Mutagenic</strong>
                                {value.mutagenic}
                              </li>
                              <li>
                                <strong>Tumorigenic</strong>
                                {value.tumorigenic}
                              </li>
                              <li>
                                <strong>Reproductive</strong>
                                {value.reproductive}
                              </li>
                              <li>
                                <strong>Irritant</strong>
                                {value.irritant}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="block-wrap">
                          <div className="block-header">
                            <h4 className="block-title">Atom Counts</h4>
                          </div>

                          <div className="block-body">
                            <ul className="dw-proprty-info">
                              <li>
                                <strong>Electronegative</strong>
                                {value.electronegative}
                              </li>
                              <li>
                                <strong>Stereo Centers</strong>
                                {value.stereo}
                              </li>
                              <li>
                                <strong>Rotatable Bonds</strong>
                                {value.rotatableBonds}
                              </li>
                              <li>
                                <strong>Aromatic Atoms</strong>
                                {value.aromaticAtoms}
                              </li>
                              <li>
                                <strong>sp3Atoms</strong>
                                {value.sp3Atoms}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="block-wrap">
                          <div className="block-header">
                            <h4 className="block-title">Ring Counts</h4>
                          </div>

                          <div className="block-body">
                            <ul className="dw-proprty-info">
                              <li>
                                <strong>Aromatic Rings</strong>
                                {value.aromaticRings}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="block-wrap">
                          <div className="block-header">
                            <h4 className="block-title">Functional Groups</h4>
                          </div>

                          <div className="block-body">
                            <ul className="dw-proprty-info">
                              <li>
                                <strong>Amides</strong>
                                {value.amides}
                              </li>
                              <li>
                                <strong>Amines</strong>
                                {value.amines}
                              </li>
                              <li>
                                <strong>Alkyl Amines</strong>
                                {value.alkylAmines}
                              </li>
                              <li>
                                <strong>Aromatic Amines</strong>
                                {value.aromaticAmines}
                              </li>
                              <li>
                                <strong>Aromatic Nitrogens</strong>
                                {value.aromaticNitrogens}
                              </li>
                              <li>
                                <strong>Basic Nitrogens</strong>
                                {value.basicNitrogens}
                              </li>
                              <li>
                                <strong>Acidic Oxygens</strong>
                                {value.acidicOxygens}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="like_share_wrap b-0">
                    <ul className="like_share_list">
                      <li>
                        <Link
                          to="#"
                          onClick={handleDownload}
                          className="btn btn-secondary"
                        >
                          <BsDownload className="shareIcons" />
                          Download
                        </Link>
                      </li>
                      <li>
                        <button
                          onClick={handleShare}
                          className="btn btn-primary"
                          data-toggle="tooltip"
                          data-original-title="Share"
                        >
                          <FaShare className="shareIcons" />
                          Share
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="like_share_wrap b-0">
                    <div className="property_block_wrap_header">
                      <h5 className="property_block_title">
                        Want to Learn More ??
                      </h5>
                    </div>
                    <ul className="know_more">
                      {value.zinc !== 'N/A' && (
                        <li>
                          <a
                            href={`https://zinc15.docking.org/substances/${value.zinc}`}
                            className="btn btn-likes"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Zinc Database
                          </a>
                        </li>
                      )}

                      {value.pubchem !== 'N/A' && (
                        <li>
                          <a
                            href={`https://pubchem.ncbi.nlm.nih.gov/compound/${value.pubchem}`}
                            className="btn btn-likes"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            PubChem Database
                          </a>
                        </li>
                      )}

                      {value.drugbank !== 'N/A' && (
                        <li>
                          <a
                            href={`https://go.drugbank.com/drugs/${value.drugbank}`}
                            className="btn btn-likes"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Drug Bank Database
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </section>
    </>
  );
};

export default Index;
