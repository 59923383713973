import React from 'react';
import { usePagination, DOTS } from '../../hooks/usePagination';
import { GrLinkPrevious, GrLinkNext } from 'react-icons/gr';
const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className="pagination p-center">
      <li className="page-item">
        <span
          className={`page-link ${currentPage === 1 && 'disabled'}`}
          onClick={onPrevious}
          aria-label="Previous"
        >
          <GrLinkPrevious className="ti-arrow-left" />
          <span className="sr-only">Previous</span>
        </span>
      </li>

      {paginationRange.map((pageNumber, i) => {
        if (pageNumber === DOTS) {
          return (
            <li className="page-item" key={i}>
              <span className="page-link">&#8230;</span>
            </li>
          );
        }

        return (
          <li
            className={`page-item ${pageNumber === currentPage && 'active'}`}
            onClick={() => onPageChange(pageNumber)}
            key={i}
          >
            <span className="page-link">{pageNumber}</span>
          </li>
        );
      })}
      <li
        className={`page-item ${currentPage === lastPage && 'disabled'}`}
        onClick={onNext}
      >
        <span className="page-link" aria-label="Next">
          <GrLinkNext className="ti-arrow-right" />
        </span>
      </li>
    </ul>
  );
};

export default Pagination;
