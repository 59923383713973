import React from 'react';
import logo from '../../assets/images/phytomed-logo.svg';
import './style.css';
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Index = () => {
  const d = new Date();
  return (
    <footer className="dark-footer skin-dark-footer">
      <div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6  col-md-4 col-12">
              <div className="footer-widget">
                <div className="footer-add" style={{ lineHeight: '2px' }}>
                  <p>
                    Nepal’s first phytochemical database of medicinal plants.
                    <a href="/about-us" style={{ color: 'white' }}>
                      {'  '} learn More
                    </a>
                  </p>
                  <h6 style={{ color: 'white' }}>Disclaimer</h6>
                  <p>
                    This database aims to gather information from scientific
                    sources about medicinal plants in Nepal. Its purpose is to
                    support research and industry efforts. Please use the
                    information for research or with expert guidance only. It
                    should not be relied upon for self-diagnosis or medical
                    treatment.
                  </p>
                  <p>
                    We encourage suggestions from researchers and industry to
                    further improve the database.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-12"></div>
            <div className="col-lg-2 col-md-4 col-12">
              <div className="footer-widget">
                <ul className="footer-menu">
                  {/* <li>
                    <Link to="/blog">Blog</Link>
                  </li> */}
                  <li>
                    <Link to="/about-us" style={{ color: 'white' }}>
                      About
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/faqs">FAQs</Link>
                  </li>
                  <li>
                    <Link to="/terms">Terms & Conditions</Link>
                  </li> */}
                  <li>
                    <Link to="/contact" style={{ color: 'white' }}>
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" style={{ color: 'white' }}>
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 col-12">
              <div className="footer-widget">
                <ul className="footer-menu">
                  <li>
                    <Link to="/plantae" style={{ color: 'white' }}>
                      Plantae
                    </Link>
                  </li>
                  <li>
                    <Link to="/fungi" style={{ color: 'white' }}>
                      Fungi
                    </Link>
                  </li>
                  <li>
                    <Link to="/chemical-library" style={{ color: 'white' }}>
                      Phytochemicals
                    </Link>
                  </li>
                  <li>
                    <Link to="/disease-library" style={{ color: 'white' }}>
                      Ethnobotanical practices
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom" style={{ background: '#066876' }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="text-center">
              <p className="mb-0">
                © {d.getFullYear()}{' '}
                <Link to="/" style={{ color: 'white' }}>
                  Phytomed Nepal{' '}
                </Link>
                All Rights Reserved
              </p>
            </div>

            {/* <div className="col-lg-6 col-md-6 text-right">
              <ul className="footer-bottom-social">
                <li>
                  <a href="https://www.facebook.com/phyotmed">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/phyotmed">
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/phyotmed">
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/phyotmed">
                    <FaLinkedin />
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Index;
