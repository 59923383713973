import React from 'react';
import { GrClose } from 'react-icons/gr';

const index = (props) => {
  function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
  }
  return (
    <>
      <div
        className={`modal fade show ${props.modal && 'showmodal'}`}
        id="login"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="registermodal"
        aria-modal="true"
      >
        <div
          className="modal-dialog modal-dialog-centered login-pop-form"
          role="document"
        >
          <div className="modal-content" id="registermodal">
            <span
              className="mod-close"
              data-bs-dismiss="modal"
              aria-hidden="true"
            >
              <GrClose
                className="ti-close"
                onClick={(e) => props.modalset(false)}
              />
            </span>
            <div className="modal-body">
              <h2 className="modal-header-title">Element Properties</h2>
              <div className="mt-3">
                {props?.modalData?.map((item, i) => (
                  <div key={i}>
                    <p>Name: {item?.name}</p>
                    <p>Standard State: {item?.phase}</p>
                    <p>Atomic Mass: {item?.atomic_mass?.toFixed(2)}u</p>
                    <p>
                      Density: {item?.density} g/cm<sup>3</sup>
                    </p>
                    <p>
                      Electron Affinity:{' '}
                      {item.electron_affinity
                        ? item.electron_affinity.toFixed(2) + 'eV'
                        : 'N/A'}{' '}
                    </p>
                    <p>
                      Electronegativity (Pauling Scale) :{' '}
                      {item.electronegativity_pauling
                        ? item.electronegativity_pauling.toFixed(2)
                        : 'N/A'}
                    </p>
                    <p>Boiling Point: {item.boil ? item.boil : 'N/A'} K</p>
                    <p>Melting Point: {item.melt ? item.melt : 'N/A'} K</p>
                    <p className="appearance">
                      Appearance:{' '}
                      {item.appearance
                        ? capitalizeFirstLetter(item.appearance)
                        : 'N/A'}
                    </p>

                    <p>
                      Discovered By:{' '}
                      {item.discovered_by
                        ? capitalizeFirstLetter(item.discovered_by)
                        : 'N/A'}
                    </p>

                    <p>
                      Color:{' '}
                      {item.color ? capitalizeFirstLetter(item.color) : 'N/A'}
                    </p>
                    <a
                      href={item?.source}
                      className="btn search-btn black"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View in Wiki
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.modal && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default index;
