import React from 'react';
import './style.css';

const PrivacyPolicy = () => {
  return (
    <div>
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="story-wrap explore-content">
                <h2>Privacy Policy</h2>
              </div>
              <div>
                At Phytomed Nepal, we are committed to safeguarding the privacy
                of our website visitors. This Privacy Policy outlines the types
                of personal information collected by our website
                (phytomednepal.com) and how we use, protect, and disclose that
                information. By using our website, you consent to the terms of
                this Privacy Policy.
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="icon-mi-left">
                <div className="icon-mi-left-content">
                  <h4>Information We Collect:</h4>
                  <p>
                    <span style={{ fontWeight: 700 }}>
                      Personal Information:
                    </span>{' '}
                    We may collect personal information such as your name, email
                    address, phone number, and other contact details when you
                    voluntarily provide them to us through forms on our website.{' '}
                    <br /> <span style={{ fontWeight: 700 }}>Usage Data:</span>{' '}
                    We may also collect non-personal information about how you
                    interact with our website, including your IP address,
                    browser type, referring website, pages viewed, and the
                    duration of your visit. This information helps us analyze
                    trends, administer the site, and improve user experience.
                  </p>
                </div>
              </div>
              <div className="icon-mi-left">
                <div className="icon-mi-left-content">
                  <h4>How We Use Your Information:</h4>
                  <p>
                    <span style={{ fontWeight: 700 }}>Communication: </span> We
                    may use your contact information to respond to your
                    inquiries, provide requested services, and communicate with
                    you about updates, promotions, and other relevant
                    information related to our products and services. <br />{' '}
                    <span style={{ fontWeight: 700 }}>
                      Improvement of Services:{' '}
                    </span>{' '}
                    We may use usage data to analyze website usage patterns,
                    identify areas for improvement, and enhance the
                    functionality and performance of our website.
                  </p>
                </div>
              </div>
              <div className="icon-mi-left">
                <div className="icon-mi-left-content">
                  <h4>Information Sharing and Disclosure:</h4>
                  <p>
                    <span style={{ fontWeight: 500 }}>
                      Third-Party Service Providers:{' '}
                    </span>{' '}
                    We may share your personal information with trusted
                    third-party service providers who assist us in operating our
                    website, conducting business, or servicing you, provided
                    that they agree to keep this information confidential.
                    <br />{' '}
                    <span style={{ fontWeight: 500 }}>
                      Legal Compliance:
                    </span>{' '}
                    We may disclose your personal information if required to do
                    so by law or in response to valid legal requests, such as
                    court orders or subpoenas.
                  </p>
                </div>
              </div>
              <div className="icon-mi-left">
                <div className="icon-mi-left-content">
                  <h4>Data Security:</h4>
                  <p>
                    We take appropriate measures to protect the security of your
                    personal information and prevent unauthorized access,
                    disclosure, alteration, or destruction. However, no method
                    of transmission over the internet or electronic storage is
                    completely secure, and we cannot guarantee absolute
                    security.
                  </p>
                </div>
              </div>
              <div className="icon-mi-left">
                <div className="icon-mi-left-content">
                  <h4>Your Rights:</h4>
                  <p>
                    You have the right to access, update, or delete your
                    personal information at any time. If you would like to
                    review, correct, or delete any personal information we have
                    collected from you, please contact us using the information
                    provided below.
                  </p>
                </div>
              </div>
              <div className="icon-mi-left">
                <div className="icon-mi-left-content">
                  <h4>Changes to This Privacy Policy:</h4>
                  <p>
                    We reserve the right to update or revise this Privacy Policy
                    at any time. Any changes will be effective immediately upon
                    posting the revised policy on this page. We encourage you to
                    review this Privacy Policy periodically for any updates or
                    changes.
                  </p>
                </div>
              </div>
              <div className="icon-mi-left">
                <div className="icon-mi-left-content">
                  <h4>Contact Us:</h4>
                  <p>
                    If you have any questions, concerns, or requests regarding
                    this Privacy Policy or the handling of your personal
                    information, please contact us at{' '}
                    <span style={{ fontWeight: 700 }}>
                      nepalphytomed@gmail.com
                    </span>
                    <br />
                    By using our website, you signify your acceptance of this
                    Privacy Policy. If you do not agree to this policy, please
                    do not use our website.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
