import React, { useState } from 'react';
import SmilesDrawer from 'smiles-drawer';

const Index = () => {
  const [value, setValue] = useState('');
  const [molecule, setMolecule] = useState('');
  const submitHandler = () => {
    let options = { atomVisualization: 'default', fontSizeLarge: '10' };
    let smilesDrawer = new SmilesDrawer.Drawer(options);
    SmilesDrawer.parse(value, function (tree) {
      smilesDrawer.draw(tree, 'example-canvas', 'light', false);
      setMolecule(smilesDrawer.getMolecularFormula());
    });
  };

  return (
    <div className="container mt-5 mb-5">
      <div>
        <input
          type="text"
          className="form-control"
          name="input"
          id="input"
          placeholder="Write Smiles"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <button
          type="button"
          onClick={submitHandler}
          className="btn btn-small btn-primary mt-3"
          style={{ float: 'right' }}
        >
          Submit
        </button>
      </div>
      {molecule !== '' && <div>Molecular Formula: {molecule}</div>}
      <canvas id="example-canvas" width="500" height="500"></canvas>
    </div>
  );
};

export default Index;
