import React, { useState } from 'react';
import './style.css';
import logo from '../../assets/images/phytomed-logo.svg';
import submit from '../../assets/images/submit.svg';
import user from '../../assets/images/user-light.svg';
import useWindowSize from '../../hooks/useWindowSize';
import { NavLink, useLocation } from 'react-router-dom';
import Signin from '../Signin';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { openModal } from '../../slice/modalSlice';
import { logoutSuccess } from '../../slice/authSlice';
import { FaUserCircle } from 'react-icons/fa';
import Signup from '../Signup';
import axios from 'axios';
import { LOGOUT_ROUTE } from '../../constants/config';

const Index = () => {
  const size = useWindowSize();
  const [sidebar, setSidebar] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [authbar, setAuthbar] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');

  const dispatch = useDispatch();
  const showModal = useSelector((state) => state.modal.showModal);
  const modalType = useSelector((state) => state.modal.modalType);
  const auth = useSelector((state) => state.auth);

  const handleOpenModal = () => {
    dispatch(openModal('loginModal'));
  };

  const handleLogout = async () => {
    try {
      dispatch(logoutSuccess()); // Dispatch the logoutSuccess action
      const response = await axios.post(LOGOUT_ROUTE); // Use POST for logout
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <>
      {showModal && modalType === 'loginModal' && <Signin />}

      {showModal && modalType === 'signupModal' && <Signup />}

      <div className="header header-light head-shadow">
        <div className="container">
          <nav
            id="navigation"
            className={`navigation ${
              size.width < 1205 ? 'navigation-portrait' : 'navigation-landscape'
            }`}
          >
            <div className="nav-header">
              <a className="nav-brand" href="/">
                <img src={logo} alt="logo" className="logo" width="240" />
              </a>
              <div
                className="nav-toggle"
                onClick={(e) => setSidebar(true)}
              ></div>
            </div>
            <div
              className={`nav-menus-wrapper ${
                sidebar === true
                  ? 'nav-menus-wrapper-open transition-left'
                  : 'transition-none'
              }`}
            >
              {sidebar && (
                <span
                  className="nav-menus-wrapper-close-button"
                  onClick={(e) => setSidebar(false)}
                >
                  X
                </span>
              )}
              <ul className="nav-menu">
                <li className={splitLocation[1] === '' ? 'active' : ''}>
                  <NavLink exact to="/" onClick={(e) => setSidebar(false)}>
                    Home<span className="submenu-indicator"></span>
                  </NavLink>
                </li>

                <li
                  className="nav-submenu-open"
                  onMouseEnter={() => setNavbar(true)}
                  onMouseLeave={() => setNavbar(false)}
                >
                  <a style={{ cursor: 'pointer' }} className="assmoli">
                    Database
                    <span className="submenu-indicator"></span>
                    <span className="submenu-indicator">
                      <span className="submenu-indicator-chevron"></span>
                    </span>
                  </a>
                  <ul
                    className={`nav-dropdown nav-submenu ${
                      navbar ? 'lib-nav-show' : 'lib-nav-hide'
                    }`}
                  >
                    <li
                      className={splitLocation[1] === 'plantae' ? 'active' : ''}
                    >
                      <NavLink
                        exact
                        to="/plantae"
                        onClick={(e) => setSidebar(false)}
                      >
                        Plantae
                        <span className="submenu-indicator"></span>
                      </NavLink>
                    </li>
                    <li
                      className={
                        splitLocation[1] === 'disease-library' ? 'active' : ''
                      }
                    >
                      <NavLink
                        exact
                        to="/fungi"
                        onClick={(e) => setSidebar(false)}
                      >
                        Fungi
                        <span className="submenu-indicator"></span>
                      </NavLink>
                    </li>
                    <li
                      className={
                        splitLocation[1] === 'disease-library' ? 'active' : ''
                      }
                    >
                      <NavLink
                        exact
                        to="/chemical-library"
                        onClick={(e) => setSidebar(false)}
                      >
                        Phytochemicals
                        <span className="submenu-indicator"></span>
                      </NavLink>
                    </li>
                    <li
                      className={
                        splitLocation[1] === 'disease-library' ? 'active' : ''
                      }
                    >
                      <NavLink
                        exact
                        to="/disease-library"
                        onClick={(e) => setSidebar(false)}
                      >
                        Ethnobotanical Practise
                        <span className="submenu-indicator"></span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className={splitLocation[1] === 'our-team' ? 'active' : ''}>
                  <NavLink exact to="/our-team">
                    Our Team
                  </NavLink>
                </li>

                <li className={splitLocation[1] === 'about-us' ? 'active' : ''}>
                  <NavLink exact to="/about-us">
                    About Us
                  </NavLink>
                </li>

                <li className={splitLocation[1] === 'contact' ? 'active' : ''}>
                  <NavLink exact to="/contact">
                    Contact
                  </NavLink>
                </li>
              </ul>

              <ul className="nav-menu nav-menu-social align-to-right">
                <li>
                  <Link to="/request" className="text-success">
                    <img src={submit} width="20" alt="" className="mr-2" />
                    Request Data
                  </Link>
                </li>
                {auth.isAuthenticated ? (
                  <li
                    className="nav-submenu-open"
                    onMouseEnter={() => setAuthbar(true)}
                    onMouseLeave={() => setAuthbar(false)}
                  >
                    <a style={{ cursor: 'pointer' }} className="assmoli">
                      <FaUserCircle size={25} />
                      <span className="submenu-indicator"></span>
                      <span className="submenu-indicator">
                        <span className="submenu-indicator-chevron"></span>
                      </span>
                    </a>
                    <ul
                      className={`nav-dropdown nav-submenu ${
                        authbar ? 'lib-nav-show' : 'lib-nav-hide'
                      }`}
                      style={{
                        right: 'auto',
                        minWidth: '80px',
                      }}
                    >
                      <li>Hello {auth.user.name} !!!</li>
                      <li>
                        <button
                          className="btn btn-primary"
                          onClick={handleLogout}
                          style={{
                            textAlign: 'center',
                            marginTop: '10px',
                          }}
                        >
                          Logout
                          <span className="submenu-indicator"></span>
                        </button>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <li className="add-listing">
                    <Link to="#" onClick={handleOpenModal}>
                      <img src={user} width="12" alt="" className="mr-2" />
                      Sign In
                    </Link>
                  </li>
                )}
              </ul>
            </div>
            {sidebar && (
              <div
                className="nav-overlay-panel"
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'block',
                }}
              ></div>
            )}
          </nav>
        </div>
      </div>
    </>
  );
};

export default Index;
