import React from 'react';
import Periodic from '../../components/Periodic';

const index = () => {
  return (
    <div className="container mt-5 pagalhamza mb-5">
      <div className="row">
        <div className="col text-center">
          <div className="sec-heading center">
            <h2>Periodic Table of Elements</h2>
          </div>
        </div>
      </div>
      <Periodic />
    </div>
  );
};

export default index;
