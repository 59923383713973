import React, { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  GET_ALL_PLANTS,
  GET_PLANTAE_FILTER,
  GET_SPECIFIC_FILTER,
  GET_CLASS_FILTER,
} from '../../constants/config';
import List from '../../components/List';
import Select from 'react-select';
import { BsSearch } from 'react-icons/bs';
import Pagination from '../../components/Pagination';

const Index = () => {
  const [selectedValue, setSelectedValue] = useState([]);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  let routeval = '';
  if (splitLocation[1] === 'plantae') {
    routeval = 'Plantae';
  } else {
    routeval = 'Fungi';
  }
  const [value, setValue] = useState({});
  const [page, setPage] = useState(1);
  const [totalpage, setTotalPage] = useState(1);

  const [division, setDivision] = useState([]);
  const [genus, setGenus] = useState([]);
  const [plantclass, setPlantClass] = useState([]);
  const [order, setOrder] = useState([]);

  const [filterText, setFilterText] = useState('');

  const handleSearchChange = (e) => {
    const getsearch = async () => {
      await axios({
        url: `${GET_PLANTAE_FILTER}?type=${routeval}&q=${e.target.value}`,
        method: 'GET',
        dataType: 'json',
      })
        .then(({ data }) => {
          setValue(data);
        })
        .catch((err) => {
          alert('Network Error');
        });
    };
    if (e.target.value.length > 3) {
      getsearch();
    }
    if (e.target.value.length === 1) {
      setPage(1);
    }
  };

  const handleChange = async (value, action) => {
    setFilterText('');
    const getsearch = async () => {
      await axios({
        url: `${GET_CLASS_FILTER}?type=${action.name}&value=${value.label}`,
        method: 'GET',
        dataType: 'json',
      })
        .then(({ data }) => {
          setValue(data);
          setSelectedValue([]);
          setFilterText(`Filtered by ${action.name}: ${value.label}`);
        })
        .catch((err) => {
          alert(err);
        });
    };
    getsearch();
  };

  useEffect(() => {
    setFilterText('');
    const getAllPlants = async () => {
      setValue({});
      await axios({
        url: `${GET_ALL_PLANTS}?type=${routeval}&page=${page}`,
        method: 'GET',
        dataType: 'json',
      })
        .then(({ data }) => {
          setTotalPage(data.totalPage);
          setValue(data);
        })
        .catch((e) => {
          alert('Network Error');
        });
    };

    const getFilter = async () => {
      await axios({
        url: `${GET_SPECIFIC_FILTER}?type=${routeval}`,
        method: 'GET',
        dataType: 'json',
      })
        .then(({ data }) => {
          setDivision(
            data.findDivision.map((a, i) => ({
              label: a,
              value: i,
            }))
          );
          setGenus(
            data.findGenus.map((a, i) => ({
              label: a,
              value: i,
            }))
          );
          setPlantClass(
            data.findClass.map((a, i) => ({
              label: a,
              value: i,
            }))
          );
          setOrder(
            data.findOrder.map((a, i) => ({
              label: a,
              value: i,
            }))
          );
        })
        .catch((err) => {
          alert('Network Error');
        });
    };
    getFilter();
    getAllPlants();
  }, [page, routeval]);

  return (
    <>
      {Object.keys(value).length === 0 ? (
        <div id="preloader">
          <div className="preloader">
            <span></span>
            <span></span>
          </div>
        </div>
      ) : (
        <>
          <div className="page-title">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <h2 className="ipt-title">{routeval ? routeval : ''}</h2>
                  <span className="ipn-subtitle">
                    Explore our extensive collection of{' '}
                    {routeval ? routeval : ''}, their ethnobotanical uses, and
                    chemical compositions. Feel free to delve into the details
                    of any {routeval ? routeval : ''} you discover, and please
                    notify us if you come across any inaccuracies.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <section className="bg-light">
            <div className="container">
              <div className="row single-widget">
                <div className="col-md-4">
                  <div className="form-group">
                    <div className="input-with-icon redass">
                      <input
                        type="text"
                        className="form-control red"
                        placeholder="Search"
                        onChange={handleSearchChange}
                      />
                      <BsSearch width="20" />
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <Select
                      value={selectedValue}
                      isClearable={true}
                      options={division}
                      classNamePrefix="select"
                      name="division"
                      placeholder="Division"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <Select
                      value={selectedValue}
                      options={genus}
                      isClearable={true}
                      classNamePrefix="select"
                      name="genus"
                      placeholder="Genus"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <Select
                      value={selectedValue}
                      options={plantclass}
                      isClearable={true}
                      classNamePrefix="select"
                      name="class"
                      placeholder="Class"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <Select
                      value={selectedValue}
                      options={order}
                      isClearable={true}
                      classNamePrefix="select"
                      name="order"
                      placeholder="Order"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  Showing{' '}
                  {value.resultsPerPage > value.totalDocuments
                    ? value.totalDocuments
                    : value.resultsPerPage}{' '}
                  of {value.totalDocuments} results
                </span>
              </div>

              <div className="item-shorting clearfix">
                <div className="left-column pull-left">
                  <h4 className="m-0">{filterText}</h4>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-lg-12 col-sm-12 list-layout">
                  <div className="row">
                    {value?.searchResults?.map((a, i) => (
                      <List a={a} key={i} />
                    ))}
                  </div>

                  {value?.searchResults?.length > 0 ? (
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <Pagination
                          currentPage={page}
                          totalCount={value?.totalDocuments}
                          pageSize={value?.resultsPerPage}
                          onPageChange={(page) => setPage(page)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="ipn-subtitle"> Sorry Nothing Found</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Index;
