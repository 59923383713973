import React, { useState } from 'react';
import './style.css';
import BGImage from '../../assets/images/hero-banner.jpg';
import Search from '../../assets/images/search.svg';
import {
  MdOutlineDraw,
  MdScreenSearchDesktop,
  MdOutlineOpenInBrowser,
  MdTableChart,
} from 'react-icons/md';
import { Link } from 'react-router-dom';
import AdvanceSearch from '../AdvanceSearch';
import DrawMolecule from '../DrawMolecule';
import axios from 'axios';
import { GET_ADVANCE_SEARCH } from '../../constants/config';
import { useDebounce } from '../../hooks/useDebounce';

const Index = () => {
  const [loader, setLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState({});
  const [pressedEnterOnSearchBar, setPressedEnterOnSearchBar] = useState(false);

  const handleSearch = (searchQuery) => {
    setSearchTerm(searchQuery); // Update searchTerm immediately
    setLoader(true); // Show loader

    // Simulating API call with setTimeout
    setTimeout(() => {
      if (searchQuery.length > 2) {
        // Make API call using axios
        axios
          .get(GET_ADVANCE_SEARCH + searchQuery)
          .then((response) => {
            setSearchResult(response.data);
          })
          .catch((error) => {
            alert('Network Error');
          })
          .finally(() => {
            setLoader(false); // Hide loader
          });
      } else {
        setSearchResult({});
        setLoader(false); // Hide loader
      }
    }, 500); // Debounce time
  };

  const handleChange = (e) => {
    const searchQuery = e.target.value;
    setSearchTerm(searchQuery); // Update searchTerm with the input value

    if (searchQuery.trim().length === 0) {
      setSearchResult({}); // Clear search result if input is empty or whitespace
    } else {
      handleSearch(searchQuery); // Call handleSearch function with the searchQuery
    }
  };

  const [modal, setModal] = useState(false);
  const [drawmodal, setDrawModal] = useState(false);
  return (
    <>
      <AdvanceSearch modalset={(modal) => setModal(modal)} modal={modal} />
      <DrawMolecule
        modalset={(drawmodal) => setDrawModal(drawmodal)}
        drawmodal={drawmodal}
      />
      <div
        className="image-cover hero-banner"
        style={{
          background: `url(${BGImage}) no-repeat`,
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-md-11 col-sm-12">
              <div className="inner-banner-text text-center">
                <p className="lead-i">
                  Easily access standardized information about medicinal plants
                  of Nepal
                </p>
                <h2>
                  <span className="font-normal">Welcome to </span> PhytoMed
                  Nepal
                </h2>
              </div>
              <div className="full-search-2 eclip-search italian-search hero-search-radius shadow-hard mt-5">
                <div className="hero-search-content">
                  <div className="row">
                    <div className="col-lg-12 col-md-9 col-sm-12 p-0 elio">
                      <div className="form-group custom-form-group">
                        <div className="input-with-icon cst-input">
                          <input
                            type="text"
                            className="form-control my-input-with-icon"
                            placeholder="Search... "
                            autoFocus={true}
                            onChange={handleChange}
                            value={searchTerm}
                          />
                          <img src={Search} width="20" alt="mainimg" />
                          {loader && (
                            <div id="dataResult preloader">
                              <div className="preloader">
                                <span></span>
                                <span></span>
                              </div>
                            </div>
                          )}
                          <Link
                            to="/plantae"
                            className="btn search-btn black search-bbtn"
                          >
                            Search
                          </Link>
                        </div>
                      </div>
                      <div
                        className={`dataResult ${
                          Object.keys(searchResult).length !== 0 &&
                          'dataResultShow'
                        }`}
                      >
                        {searchResult?.findChemical?.length > 0 &&
                          searchResult.findChemical.map((data, i) => (
                            <Link
                              to={`/chemical-library/${data.slug}`}
                              className="dataItem"
                              key={i}
                            >
                              {data.name
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                                ? data.name
                                : ''}

                              {data.zinc
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                                ? data.zinc
                                : ''}

                              {data.pubchem
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                                ? data.pubchem
                                : ''}

                              {data.drugbank
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                                ? data.drugbank
                                : ''}

                              {data.phytomed
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                                ? data.phytomed
                                : ''}

                              {data.formula
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                                ? data.formula
                                : ''}

                              {data.smiles
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                                ? data.smiles
                                : ''}

                              {data.inchlKey
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                                ? data.inchlKey
                                : ''}
                            </Link>
                          ))}
                        {searchResult?.findDivision?.length > 0 &&
                          searchResult.findDivision.map((data, i) => (
                            <Link
                              to={`/division/${data}`}
                              className="dataItem"
                              key={i}
                            >
                              {data}
                            </Link>
                          ))}
                        {searchResult?.findKingdom?.length > 0 &&
                          searchResult.findKingdom.map((data, i) => (
                            <Link
                              to={`/kingdom/${data}`}
                              className="dataItem"
                              key={i}
                            >
                              {data}
                            </Link>
                          ))}
                        {searchResult?.findUse?.length > 0 &&
                          searchResult.findUse.map((data, i) => (
                            <Link
                              to={`/uses/${data.slug}`}
                              className="dataItem"
                              key={i}
                            >
                              {data.name}
                            </Link>
                          ))}
                        {searchResult?.searchResults?.length > 0 &&
                          searchResult.searchResults.map((data, i) => (
                            <Link
                              to={`/plant/${data.slug}`}
                              className="dataItem"
                              key={i}
                            >
                              {data.english_name
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                                ? data.english_name
                                : ''}
                              {data.nepali_name
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                                ? data.nepali_name
                                : ''}
                              {data.binomial_name
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                                ? data.binomial_name
                                : ''}
                              {data.synonym
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                                ? data.synonym
                                : ''}
                            </Link>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <p className="search-bottom-text">
                      <span className="btext">Try</span>
                      <span className="btext">Cordyceps sinensis</span>
                      <span className="btext">Aspirin</span>
                      <span className="btext">C10H13N5O3</span>
                      <span className="btext">OFEZSBMBBKLLBJ-JFWOZONXSA-N</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="hero-footer">
                <div className="col-md-12 mt-4">
                  <div className="row">
                    <Link
                      to="#"
                      onClick={(e) => setDrawModal(true)}
                      className="col-lg-3 col-md-3 col-sm-12 link phy-hero-icon"
                    >
                      <MdOutlineDraw />
                      <p className="phy-hero-icons">Draw Strucutre</p>
                    </Link>
                    <Link
                      to="#"
                      onClick={(e) => setModal(true)}
                      className="col-lg-3 col-md-3 col-sm-12 link phy-hero-icon"
                    >
                      <MdScreenSearchDesktop />
                      <p className="phy-hero-icons">Advanced Search</p>
                    </Link>
                    <Link
                      to="/plantae"
                      className="col-lg-3 col-md-3 col-sm-12 link phy-hero-icon"
                    >
                      <MdOutlineOpenInBrowser />
                      <p className="phy-hero-icons">Browse Data</p>
                    </Link>
                    <Link
                      to="/periodic-table"
                      className="col-lg-3 col-md-3 col-sm-12 link phy-hero-icon"
                    >
                      <MdTableChart />
                      <p className="phy-hero-icons">Periodic table</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
