import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { GET_ADVANCED_SEARCH_RESULT } from '../../constants/config';
import List from '../../components/List';
import { GrLinkPrevious, GrLinkNext } from 'react-icons/gr';
import Search from '../../assets/images/search.svg';

const Index = () => {
  const params = useParams();
  const [value, setValue] = useState({});
  const [page, setPage] = useState(1);
  const [totalpage, setTotalPage] = useState(1);
  const handleClick = (e) => {
    if (totalpage >= e && e > 0) {
      setPage(e);
    } else {
      setPage(1);
    }
    setValue({});
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    const getAllPlants = async () => {
      setValue({});
      await axios({
        url: `${GET_ADVANCED_SEARCH_RESULT}page=${page}&${params.query}`,
        method: 'GET',
        dataType: 'json',
      })
        .then(({ data }) => {
          setTotalPage(data.totalPage);
          setValue(data);
        })
        .catch((e) => {
          alert('Network Error');
        });
    };
    getAllPlants();
  }, [page]);
  return (
    <>
      {Object.keys(value).length === 0 ? (
        <div id="preloader">
          <div className="preloader">
            <span></span>
            <span></span>
          </div>
        </div>
      ) : (
        <>
          {value.status === 'success' ? (
            <section className="bg-light">
              <div className="container">
                <div className="row mt-4">
                  <div className="col-lg-12 col-sm-12 list-layout">
                    <div className="row">
                      {value?.searchResults?.map((a, i) => (
                        <List a={a} key={i} />
                      ))}
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <ul className="pagination p-center">
                          <li className="page-item">
                            <span
                              className="page-link"
                              onClick={(e) => handleClick(page - 1)}
                              aria-label="Previous"
                            >
                              <GrLinkPrevious className="ti-arrow-left" />
                              <span className="sr-only">Previous</span>
                            </span>
                          </li>

                          {Array.from(Array(value.totalPage), (e, i) => {
                            return (
                              <li
                                className={`page-item ${
                                  page === i + 1 && 'active'
                                }`}
                                key={i + 1}
                              >
                                <span
                                  className="page-link"
                                  onClick={(e) => handleClick(i + 1)}
                                >
                                  {i + 1}
                                </span>
                              </li>
                            );
                          })}

                          <li className="page-item">
                            <span
                              className="page-link"
                              onClick={(e) => handleClick(page + 1)}
                              aria-label="Next"
                            >
                              <GrLinkNext className="ti-arrow-right" />
                              <span className="sr-only">Next</span>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <div className="page-title">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <h2 className="ipt-title">Sorry</h2>
                    <span className="ipn-subtitle">
                      We can't find what you are looking for. Please try a
                      diferent filter
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Index;
