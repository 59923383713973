import React from 'react';
import { Link } from 'react-router-dom';
import ImageError from '../../assets/images/image_error.png';
import { BACKEND } from '../../constants/config';
import useWindowSize from '../../hooks/useWindowSize';

const Index = ({ a }) => {
  console.log(a);
  const size = useWindowSize();
  return (
    <div className="col-lg-6 col-md-12">
      <div className="property-listing property-1">
        <div className="listing-img-wrapper">
          <img
            src={a.image ? BACKEND + a.image : ImageError}
            className="img-fluid mx-auto"
            alt=""
          />
        </div>

        <div className="listing-content">
          <div className="listing-detail-wrapper-box">
            <div className="listing-detail-wrapper">
              <div className="listing-short-detail">
                <h4 className="listing-name" style={{ textTransform: 'none' }}>
                  <Link to={`/plant/${a.slug}`}>{a.binomial_name}</Link>
                </h4>
                <span className="prt-types sale">{a.class}</span>
              </div>
              {size.width > 500 && (
                <div className="list-price" style={{ textTransform: 'none' }}>
                  <h6 className="listing-card-info-price">{a.kingdom}</h6>
                </div>
              )}
            </div>
          </div>

          <div className="price-features-wrapper">
            <div className="list-fx-features">
              <div>
                {' '}
                <b>Family: </b>
                {'  '} {a.family}
              </div>
            </div>
          </div>

          <div className="listing-footer-wrapper">
            <div className="listing-locate">
              <b>
                {a.nepali_name
                  ? `Common Name: ${a.nepali_name}`
                  : `English Name: ${a.english_name}`}
              </b>
            </div>
            <div className="listing-detail-btn">
              <Link to={`/plant/${a.slug}`} className="prt-view">
                View
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
