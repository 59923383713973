import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import initRDKit from '../../utils/init';
import Downloader from '../../components/Downloader';

const Index = ({ a }) => {
  const [rdKitLoaded, setRdKitLoaded] = useState(false);
  const [modal, setModal] = useState(false);
  const [svg, setSvg] = useState('');
  useEffect(() => {
    initRDKit()
      .then((e) => {
        setRdKitLoaded(true);
        drawme();
      })
      .catch((error) => {
        setRdKitLoaded(false);
      });
  }, []);
  const drawme = () => {
    const mol = window.RDKit.get_mol(a.smiles);
    setSvg(mol.get_svg());
  };
  const truncate = (input) =>
    input.length > 20 ? `${input.substring(0, 26)}...` : input;
  return (
    <>
      {a.formula !== 'N/A' && (
        <div className="col-lg-3 col-md-6 col-sm-12">
          {modal && (
            <Downloader
              modalset={(modal) => setModal(modal)}
              modal={modal}
              smiles={a.smiles}
              name={a.name}
            />
          )}
          {rdKitLoaded ? (
            <div
              className="property-listing property-2"
              style={{ cursor: 'pointer' }}
            >
              <Link to={`/chemical-library/${a.slug}`}>
                <div className="listing-img-wrapper">
                  <div className="list-img-slide">
                    <div
                      dangerouslySetInnerHTML={{ __html: svg }}
                      style={{ textAlign: 'center' }}
                    ></div>
                  </div>
                </div>
              </Link>

              <div
                className="listing-detail-wrapper"
                style={{ cursor: 'pointer' }}
              >
                <Link to={`/chemical-library/${a.slug}`}>
                  <div className="listing-short-detail-wrap">
                    <div className="listing-short-detail">
                      <h4 className="listing-name verified">
                        {truncate(a.name)}
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="listing-detail-footer">
                <div className="footer-first">
                  <div className="foot-location listing-card-info-icon">
                    {a.phytomed}
                  </div>
                </div>
                <div className="footer-flex">
                  <Link
                    to="#"
                    onClick={(e) => setModal(true)}
                    className="np-download prt-view"
                  >
                    Download
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div id="preloader">
              <div className="preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Index;
