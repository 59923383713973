import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './container/Home';
import Plant from './container/Plant';
import PlantDetails from './container/PlantDetails';
import ChemicalDetail from './container/ChemicalDetail';
import Error from './container/Error';
import Footer from './components/Footer';
import ListView from './container/ListView';
import Library from './container/Library';
import AboutUs from './container/AboutUs';
import Smiles from './container/Smiles';
import PeriodicTable from './container/PeriodicTable';
import ChemicalNotFound from './container/ChemicalNotFound';
import AdvanceSearch from './container/Search';
import Request from './container/Request';
import OurTeam from './container/OurTeam';
import ContactUs from './container/ContactUs';
import ScrollToTop from './components/ScrollToTop';
import Cookie from './components/Cookie';
import useWindowSize from './hooks/useWindowSize';
import PrivacyPolicy from './container/PrivacyPolicy';
import { loginSuccess, logoutSuccess } from '../src/slice/authSlice';
import { useDispatch } from 'react-redux';
import { REFETCH_LOGIN } from './constants/config';
import { useEffect } from 'react';
import { GiConsoleController } from 'react-icons/gi';

function App() {
  const size = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        // Send a request to the backend to verify the authentication status
        const response = await fetch(REFETCH_LOGIN, {
          method: 'GET',
          credentials: 'include', // Include cookies in the request
        });
        if (response.ok) {
          const user = await response.json();
          console.log(user);
          dispatch(loginSuccess(user.data.user)); // Update Redux state with user information
        } else {
          dispatch(logoutSuccess()); // Clear Redux state if authentication fails
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
      }
    };

    checkAuthStatus(); // Call the function on component mount (page load)
  }, [dispatch]);

  return (
    <div className="main-wrapper">
      <Router>
        <ScrollToTop />
        <Header />
        <div className="pages">
          <Switch>
            {/* Menu Router */}
            <Route exact path="/" component={Home} />

            {/* Plant & Fungi */}
            <Route exact path="/plantae" component={Plant} />
            <Route exact path="/fungi" component={Plant} />

            {/* Plant Query */}
            <Route exact path="/plantae/:query" component={AdvanceSearch} />

            {/* Chemical & Disease Library */}
            <Route exact path="/chemical-library" component={Library} />
            <Route exact path="/disease-library" component={Library} />

            {/* Single Surf Router */}
            <Route path="/plant/:slug" exact component={PlantDetails} />
            <Route
              path="/chemical-library/:slug"
              exact
              component={ChemicalDetail}
            />

            {/* List View of Details */}
            <Route path="/:category/:slug" exact component={ListView} />

            {/* Basic Pages */}
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/our-team" component={OurTeam} />
            <Route exact path="/smiles" component={Smiles} />
            <Route
              exact
              path="/chemical-not-found"
              component={ChemicalNotFound}
            />

            {/* Features */}
            <Route exact path="/smiles" component={Smiles} />
            <Route exact path="/periodic-table" component={PeriodicTable} />

            <Route exact path="/request" component={Request} />

            <Route exact path="/contact" component={ContactUs} />

            <Route path="/" component={Error} />
          </Switch>
          <Footer />
          {size.width > 520 && <Cookie />}
        </div>
      </Router>
    </div>
  );
}

export default App;
