import React from 'react';
import Analytics from '../../components/Analytics';
import Container from '../../components/Section';
import Herov2 from '../../components/Herov2';

const Index = () => {
  return (
    <>
      <Herov2 />
      <Analytics />
      <Container />
    </>
  );
};

export default Index;
