import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { GET_TEAM, BACKEND } from '../../constants/config';
import ShowMoreText from 'react-show-more-text';

const Index = () => {
  const [team, setteam] = useState([]);
  useEffect(() => {
    const getteam = async () => {
      await axios({
        url: GET_TEAM,
        method: 'GET',
        dataType: 'json',
      }).then(({ data }) => {
        setteam(data.team);
      });
    };
    getteam();
  }, []);
  return (
    <section className="gray-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="sec-heading center">
              <h2>Meet Our Team</h2>
            </div>
          </div>
        </div>

        <div className="row">
          {team.length > 0 &&
            team.map((item, index) => (
              <div className="col-lg-4 col-md-4 col-sm-12" key={index}>
                <div className="single-team">
                  <div className="team-grid">
                    <div className="teamgrid-user">
                      <img
                        src={`${BACKEND}${item.image}`}
                        alt={item.name}
                        className="img-fluid"
                      />
                    </div>

                    <div className="teamgrid-content">
                      <h4>{item.name}</h4>
                      <span style={{ fontFamily: 'cursive' }}>
                        {item.designation}
                      </span>
                    </div>

                    <div className="teamgrid-social">
                      <ShowMoreText
                        /* Default options */
                        lines={4}
                        more="Show more"
                        less="Show less"
                        className="content-css"
                        anchorClass="show-more-less-clickable"
                        expanded={false}
                        truncatedEndingComponent={'... '}
                      >
                        {item.description}
                      </ShowMoreText>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Index;
