import React, { useState } from 'react';
import './style.css';
import { GrClose } from 'react-icons/gr';
import { BiUser, BiLockOpenAlt } from 'react-icons/bi';
import axios from 'axios';
import { LOGIN_ROUTE } from '../../constants/config';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../slice/authSlice';
import { closeModal, openModal } from '../../slice/modalSlice';

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    // Trim whitespace from email and password inputs
    const trimmedEmail = email.trim();
    const trimmedPassword = password.trim();

    try {
      const response = await axios.post(LOGIN_ROUTE, {
        email: trimmedEmail,
        password: trimmedPassword,
      });
      setSuccess(response.data.message);
      dispatch(loginSuccess(response.data.data.user));
      handleCloseModal();
    } catch (error) {
      setError(error.response.data);
    }
  };

  const handleSignup = () => {
    dispatch(openModal('signupModal'));
  };

  return (
    <>
      <div
        className={`modal fade show showmodal`}
        id="login"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="registermodal"
        aria-modal="true"
      >
        <div
          className="modal-dialog modal-dialog-centered login-pop-form"
          role="document"
        >
          <div className="modal-content" id="registermodal">
            <span
              className="mod-close"
              data-bs-dismiss="modal"
              aria-hidden="true"
            >
              <GrClose className="ti-close" onClick={handleCloseModal} />
            </span>
            <div className="modal-body">
              <h4 className="modal-header-title">Log In</h4>
              <div className="login-form">
                <form onSubmit={submitHandler}>
                  <div className="form-group">
                    <label>Email</label>
                    <div className="input-with-icon my-input">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <BiUser className="ti-user" />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Password</label>
                    <div className="input-with-icon my-input">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="*******"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <BiLockOpenAlt className="ti-unlock" />
                      {/* <i className="ti-unlock"></i> */}
                    </div>
                  </div>

                  <div>
                    <p style={{ color: 'red' }}>{error.message}</p>
                  </div>

                  <div>
                    <p style={{ color: 'green' }}>{success}</p>
                  </div>

                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-md full-width btn-default rounded mt-4"
                    >
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="text-center">
              <p className="">
                <button
                  onClick={handleSignup}
                  className=""
                  style={{
                    background: 'white',
                    border: 'none',
                    color: 'purple',
                  }}
                >
                  Create an account
                </button>
              </p>
            </div>

            <div className="text-center">
              <p className="">
                <a href="#" className="btn-tertiary">
                  Forgot password?
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default Signin;
