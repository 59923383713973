import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { TiTick } from 'react-icons/ti';

const Index = () => {
  const [checked, setChecked] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [nameErr, setNameErr] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [titleErr, setTitleErr] = useState('');

  const [success, setSuccess] = useState(false);

  const form = useRef();

  const [error, setError] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    if (name === '') {
      setNameErr('Please write your name');
      return;
    }

    if (email === '') {
      setEmailErr('Please write your email');
      return;
    }

    if (title === '') {
      setTitleErr('Please write your title');
      return;
    }

    if (!checked) {
      setError('Please agree to the terms and conditions');
      return;
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICEID,
        process.env.REACT_APP_TEMPLATEID,
        form.current,
        process.env.REACT_APP_USERID
      )
      .then(
        (result) => {
          setName('');
          setEmail('');
          setPhone('');
          setTitle('');
          setDescription('');
          setNameErr('');
          setTitleErr('');
          setEmailErr('');
          setSuccess(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section className="gray-simple">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="alert alert-success" role="alert">
              {success ? (
                <p>
                  <TiTick />
                  Your message has been sent successfully
                </p>
              ) : (
                <p>
                  If you couldnot find what you are looking for, please submit
                  it here &amp; we will let you know as soon as we upload.
                </p>
              )}
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="submit-page">
              <div className="form-submit">
                <h3>Contact Information</h3>
                <div className="submit-section">
                  <form onSubmit={sendEmail} ref={form}>
                    <div className="row">
                      <div className="form-group col-md-4">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          name="name"
                          className={`form-control ${
                            nameErr !== '' && 'inputerror'
                          }`}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        {nameErr !== '' && (
                          <p style={{ color: 'red' }}>{nameErr}</p>
                        )}
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="email">Email</label>
                        <input
                          type="text"
                          name="email"
                          className={`form-control ${
                            emailErr !== '' && 'inputerror'
                          }`}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {emailErr !== '' && (
                          <p style={{ color: 'red' }}>{emailErr}</p>
                        )}
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="phone">Phone (optional)</label>
                        <input
                          type="text"
                          name="phone"
                          className={`form-control`}
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>

                      <div className="form-group col-md-12">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          name="title"
                          className={`form-control ${
                            titleErr !== '' && 'inputerror'
                          }`}
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        {titleErr !== '' && (
                          <p style={{ color: 'red' }}>{titleErr}</p>
                        )}
                      </div>

                      <div className="form-group col-md-12">
                        <label htmlFor="description">Description</label>
                        <textarea
                          name="description"
                          className="form-control h-120"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                      </div>

                      {/* <ReCAPTCHA
                        g-recaptcha-response={process.env.REACT_APP_SITE_KEY}
                        sitekey={process.env.REACT_APP_SITE_KEY}
                      /> */}

                      <div className="form-group col-md-12">
                        <label>Agreement *</label>

                        <input
                          className="checkbox-custom"
                          name="aj-1"
                          type="checkbox"
                          checked={checked}
                          onChange={() => setChecked(!checked)}
                          id="registerTip"
                        />
                        <label
                          htmlFor="registerTip"
                          className={
                            error === ''
                              ? 'checkbox-custom-label'
                              : 'checkbox-custom-label newass'
                          }
                        >
                          I consent to having this website store my submitted
                          information so they can respond to my inquiry.
                        </label>
                        {error !== '' && (
                          <p style={{ color: 'red' }}>{error}</p>
                        )}
                      </div>
                      <div className="form-group col-md-12">
                        <button
                          className="btn search-btn black search-bbtn rounded"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
