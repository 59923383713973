import React, { useState, useRef } from 'react';
import { TiHome } from 'react-icons/ti';
import { IoMail } from 'react-icons/io5';
import { RiChatSmile3Fill } from 'react-icons/ri';
import './style.css';
import emailjs from '@emailjs/browser';
import { TiTick } from 'react-icons/ti';

const Index = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [success, setSuccess] = useState(false);
  const [nameErr, setNameErr] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [titleErr, setTitleErr] = useState('');

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (name === '') {
      setNameErr('Please write your name');
      return;
    }

    if (email === '') {
      setEmailErr('Please write your email');
      return;
    }

    if (title === '') {
      setTitleErr('Please write your title');
      return;
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICEID,
        process.env.REACT_APP_TEMPLATEID,
        form.current,
        process.env.REACT_APP_USERID
      )
      .then(
        (result) => {
          setTitle('');
          setDescription('');
          setName('');
          setEmail('');
          setNameErr('');
          setTitleErr('');
          setEmailErr('');
          setSuccess(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="sec-heading center">
              <h2>Meet Our Team</h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="col-lg-12 col-md-12">
              {success && (
                <div className="alert alert-success" role="alert">
                  <p>
                    <TiTick />
                    Your message has been sent successfully
                  </p>
                </div>
              )}
            </div>
            <form onSubmit={sendEmail} ref={form}>
              <div className="single-team">
                <div className="team-grid be-grid" style={{ padding: '3em' }}>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          name="name"
                          className={`form-control simple ${
                            nameErr !== '' && 'inputerror'
                          }`}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        {nameErr !== '' && (
                          <p style={{ color: 'red' }}>{nameErr}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          name="email"
                          className={`form-control simple ${
                            emailErr !== '' && 'inputerror'
                          }`}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {emailErr !== '' && (
                          <p style={{ color: 'red' }}>{emailErr}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Subject</label>
                    <input
                      type="text"
                      name="title"
                      className={`form-control simple ${
                        titleErr !== '' && 'inputerror'
                      }`}
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    {titleErr !== '' && (
                      <p style={{ color: 'red' }}>{titleErr}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label>Message</label>
                    <textarea
                      name="description"
                      className="form-control simple"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <button className="btn btn-primary rounded" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="contact-info">
              <h2>Get In Touch</h2>
              <p>
                We are a team of researchers ready to assist with your inquiries
                and eager to hear your feedback.
              </p>

              <div className="cn-info-detail">
                <div className="cn-info-icon">
                  <TiHome className="ti-home" color="#1266e3" size={22} />
                </div>
                <div className="cn-info-content">
                  <h4 className="cn-info-title">Reach Us</h4>
                  Kirtipur, Kathmandu, Nepal.
                </div>
              </div>

              <div className="cn-info-detail">
                <div className="cn-info-icon">
                  <IoMail className="ti-home" color="#1266e3" size={20} />
                </div>
                <div className="cn-info-content">
                  <h4 className="cn-info-title">Drop A Mail</h4>
                  <span>info@phytomednepal.com</span>
                  <p>nepalphytomed@gmail.com</p>
                </div>
              </div>

              <div className="cn-info-detail">
                <div className="cn-info-icon">
                  <RiChatSmile3Fill
                    className="ti-home"
                    color="#1266e3"
                    size={20}
                  />
                </div>
                <div className="cn-info-content">
                  <h4 className="cn-info-title">Call Us</h4>
                  +977 98423 29129
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
