import './style.css';
import React, { useState, useEffect } from 'react';

const Index = () => {
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    const saved = localStorage.getItem('cookieconsent');
    const initial = JSON.parse(saved);
    initial && setConsent(true);
  }, []);

  const handleconsent = () => {
    setConsent(true);
    localStorage.setItem('cookieconsent', true);
  };

  return (
    <section className={`${consent && 'd-none'}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="cookie center flex flex-row">
              <h4>Cookie Policy</h4>
              <p>
                This website uses cookies, to enhance your browsing experience.
                By continuing to use our website, you agree to our Privacy
                Policy
              </p>
              <button className="btn btn-primary" onClick={handleconsent}>
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
