export const BACKEND = "https://app.phytomednepal.com";

export const API_URL = `${BACKEND}/api/v1`;

export const GET_SEARCH_FILTER = `${API_URL}/search/filters`;

export const GET_ANALYTICS = `${API_URL}/analytics`;

export const GET_RECENT_PLANTS = `${API_URL}/plants/recents`;

export const GET_PLANT = `${API_URL}/plant/`;

export const GET_CHEMICAL = `${API_URL}/chemical/library/`;

export const GET_SIMILAR_PLANT = `${API_URL}/plants/similar/`;

export const GET_SEARCH_VARIABLE = `${API_URL}/search?q=&`;

export const GET_ALL_PLANTS = `${API_URL}/plants`;

export const GET_ADVANCE_SEARCH = `${API_URL}/getfilter?q=`;

export const GET_PLANTAE_FILTER = `${API_URL}/getplantaeFilter`;

export const GET_ADVANCED_SEARCH_RESULT = `${API_URL}/getadvanceResult?`;

export const GET_SPECIFIC_FILTER = `${API_URL}/getSpecificFilter`;

export const GET_CLASS_FILTER = `${API_URL}/getClassFilter`;

export const GET_CHEMICAL_FROM_SMILES = `${API_URL}/getchemSmiles`;

export const GET_CHEMICAL_SEARCH = `${API_URL}/getChemSearch`;

export const GET_ABOUT_US = `${API_URL}/aboutus`;

export const GET_TEAM = `${API_URL}/ourteam`;

export const LOGIN_ROUTE = `${API_URL}/login`;

export const SIGNUP_ROUTE = `${API_URL}/register`;

export const LOGOUT_ROUTE = `${API_URL}/logout`;

export const REFETCH_LOGIN = `${API_URL}/fetch-auth`;
