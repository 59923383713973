import React, { useState } from 'react';
import { GrClose } from 'react-icons/gr';
import { Jsme } from 'jsme-react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { GET_CHEMICAL_FROM_SMILES } from '../../constants/config';

const Index = (props) => {
  let history = useHistory();
  const [chemical, setchemical] = useState([]);
  const logSmiles = (smiles) => {
    setchemical(smiles);
  };

  const submitSearch = async () => {
    await axios({
      url: `${GET_CHEMICAL_FROM_SMILES}?smiles=${chemical}`,
      method: 'GET',
      dataType: 'json',
    })
      .then(({ data }) => {
        if (data.status === 'no_data') {
          alert('Chemical not found');
        } else {
          history.push(`/chemical-library/${data.library.slug}`);
        }
      })
      .catch((err) => {
        alert('Network Error');
      });
  };

  return (
    <>
      <div
        className={`modal fade signup show ${props.drawmodal && 'showmodal'}`}
        id="signup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="sign-up"
        aria-modal="true"
      >
        <div
          className="modal-dialog modal-dialog-centered login-pop-form"
          role="document"
        >
          <div className="modal-content" id="sign-up">
            <span
              className="mod-close"
              data-bs-dismiss="modal"
              aria-hidden="true"
            >
              <GrClose
                className="ti-close"
                onClick={(e) => props.modalset(false)}
              />
            </span>
            <div className="modal-body">
              <h2 className="modal-header-title">Draw &amp; Search Chemical</h2>
              <div className="login-form">
                <Jsme
                  height="30rem"
                  width="50rem"
                  options="newlook,star,noxbutton,toggle,atommovebutton,guicolor:'#fff'"
                  onChange={logSmiles}
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-md full-width btn-default rounded mt-4"
                  onClick={submitSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.drawmodal && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default Index;
