import React from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { API_URL, GET_CHEMICAL_SEARCH } from '../../constants/config';
import Chemical from '../../components/Chemical';
import Disease from '../../components/Disease';
import Pagination from '../../components/Pagination';
import { BsSearch } from 'react-icons/bs';

const Index = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  let routeval = '';
  if (splitLocation[1] === 'chemical-library') {
    routeval = 'chemical';
  } else {
    routeval = 'disease';
  }
  const [value, setValue] = useState({});
  const [page, setPage] = useState(1);
  const [totalpage, setTotalPage] = useState(1);

  const handleSearchChange = (e) => {
    const getsearch = async () => {
      await axios({
        url: `${GET_CHEMICAL_SEARCH}?q=${e.target.value}`,
        method: 'GET',
        dataType: 'json',
      })
        .then(({ data }) => {
          setValue(data);
        })
        .catch((err) => {
          alert('Network Error');
        });
    };
    getsearch();
  };

  useEffect(() => {
    const getAllPlants = async () => {
      setValue({});
      await axios({
        url: `${API_URL}/${routeval}/library?page=${page}`,
        method: 'GET',
        dataType: 'json',
      })
        .then(({ data }) => {
          setTotalPage(data.totalPage);
          setValue(data);
        })
        .catch((e) => {
          alert('Network Error');
        });
    };
    getAllPlants();
  }, [page, routeval]);

  return (
    <>
      {Object.keys(value).length === 0 ? (
        <div id="preloader">
          <div className="preloader">
            <span></span>
            <span></span>
          </div>
        </div>
      ) : (
        <>
          <div className="page-title">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <h2 className="ipt-title">{routeval ? routeval : ''}</h2>
                  <span className="ipn-subtitle">
                    Explore our extensive collection of f{' '}
                    {routeval ? routeval : ''} . Feel free to delve into the
                    details of any f {routeval ? routeval : ''} you discover,
                    and please notify us if you come across any inaccuracies.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <section>
            <div className="container">
              {routeval === 'chemical' && (
                <div className="row single-widget">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="input-with-icon redass">
                        <input
                          type="text"
                          className="form-control red"
                          placeholder="Try Chemical Name, Chemical Formula, Phytomed ID or Anything...."
                          onChange={handleSearchChange}
                        />
                        <BsSearch width="20" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                {routeval === 'chemical' &&
                  value?.libraries?.map(
                    (a, i) =>
                      (a.formula !== 'a' || a.formula === 'N/A') && (
                        <Chemical a={a} key={i} />
                      )
                  )}
                {routeval === 'disease' &&
                  value?.diseases?.map((a, i) => <Disease a={a} key={i} />)}
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <Pagination
                    currentPage={page}
                    totalCount={value?.totalDocuments}
                    pageSize={value?.resultsPerPage}
                    onPageChange={(page) => setPage(page)}
                  />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      ;
    </>
  );
};

export default Index;
