import React, { useEffect, useState } from 'react';
import './style.css';
import sb from '../../assets/images/sb.png';

import { GET_ABOUT_US } from '../../constants/config';
import axios from 'axios';

const Index = () => {
  const [story, setStory] = useState('');
  const [mission, setMission] = useState([]);
  useEffect(() => {
    const getsearch = async () => {
      await axios({
        url: GET_ABOUT_US,
        method: 'GET',
        dataType: 'json',
      }).then(({ data }) => {
        setStory(data.about.story);
        setMission(data.about.ourmission);
      });
    };
    getsearch();
  }, []);

  return (
    <div>
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <img src={sb} className="img-fluid" alt="" />
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="story-wrap explore-content">
                <h2>Our Story</h2>
              </div>
              {story !== '' && (
                <div dangerouslySetInnerHTML={{ __html: story }}></div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="sec-heading center">
                <h2>Our Mission & Work Process</h2>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              {mission.length > 0 &&
                mission.map((item, index) => (
                  <div className="icon-mi-left" key={index}>
                    <div className="icon-mi-left-content">
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Index;
