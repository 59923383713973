import React, { useState } from 'react';
import './style.css';
import { GrClose } from 'react-icons/gr';
import FileSaver from 'file-saver';

const Index = (props) => {
  const [value, setValue] = useState();

  const submitHandler = () => {
    const mol = window.RDKit.get_mol(props.smiles);
    switch (value) {
      case 'json':
        var blob = new Blob([mol.get_json()], {
          type: 'text/json;charset=utf-8',
        });
        FileSaver.saveAs(blob, `${props.name}-phytomed.json`);
        break;
      case 'sdf':
        var sdfoutput = new Blob([mol.get_aromatic_form()], {
          type: 'text/plain;charset=utf-8',
        });
        FileSaver.saveAs(sdfoutput, `${props.name}-phytomed.sdf`);
        break;
      case 'svg':
        var svgoutput = new Blob([mol.get_svg()], {
          type: 'text/plain;charset=utf-8',
        });
        FileSaver.saveAs(svgoutput, `${props.name}-phytomed.svg`);
        break;
      case 'descriptors':
        var descriptorsoutput = new Blob([mol.get_descriptors()], {
          type: 'text/json;charset=utf-8',
        });
        FileSaver.saveAs(descriptorsoutput, `${props.name}-phytomed.json`);
        break;
      case 'smiles':
        var smilesoutput = new Blob([mol.get_cxsmiles()], {
          type: 'text/plain;charset=utf-8',
        });
        FileSaver.saveAs(smilesoutput, `${props.name}-phytomed.txt`);
        break;
      default:
        console.log('default');
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      <div
        className={`modal fade show ${props.modal && 'showmodal'}`}
        id="login"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="registermodal"
        aria-modal="true"
      >
        <div
          className="modal-dialog modal-dialog-centered login-pop-form"
          role="document"
        >
          <div className="modal-content" id="registermodal">
            <span
              className="mod-close"
              data-bs-dismiss="modal"
              aria-hidden="true"
            >
              <GrClose
                className="ti-close"
                onClick={(e) => props.modalset(false)}
              />
            </span>
            <div className="modal-body">
              <h2 className="modal-header-title">Select Format</h2>
              <div className="login-form">
                <ul className="no-ul-list third-row">
                  <li>
                    <input
                      id="json"
                      className="checkbox-custom"
                      name="json"
                      type="checkbox"
                      value="json"
                      onChange={handleChange}
                      checked={value === 'json'}
                    />
                    <label htmlFor="json" className="checkbox-custom-label">
                      JSON
                    </label>
                  </li>
                  <li>
                    <input
                      id="sdf"
                      className="checkbox-custom"
                      type="checkbox"
                      value="sdf"
                      onChange={handleChange}
                      checked={value === 'sdf'}
                    />
                    <label htmlFor="sdf" className="checkbox-custom-label">
                      SDF File
                    </label>
                  </li>
                  <li>
                    <input
                      id="svg"
                      className="checkbox-custom"
                      type="checkbox"
                      value="svg"
                      onChange={handleChange}
                      checked={value === 'svg'}
                    />
                    <label htmlFor="svg" className="checkbox-custom-label">
                      SVG File
                    </label>
                  </li>
                  <li>
                    <input
                      id="descriptors"
                      className="checkbox-custom"
                      type="checkbox"
                      value="descriptors"
                      onChange={handleChange}
                      checked={value === 'descriptors'}
                    />
                    <label
                      htmlFor="descriptors"
                      className="checkbox-custom-label"
                    >
                      Descriptor File
                    </label>
                  </li>
                  <li>
                    <input
                      id="smiles"
                      className="checkbox-custom"
                      type="checkbox"
                      value="smiles"
                      onChange={handleChange}
                      checked={value === 'smiles'}
                    />
                    <label htmlFor="smiles" className="checkbox-custom-label">
                      Smiles
                    </label>
                  </li>
                </ul>

                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-md full-width btn-default rounded mt-4"
                    onClick={submitHandler}
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.modal && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default Index;
